import React from "react"

import cn from "classnames"

import Link from "~/components/Link/Link"
import Separator from "~/components/Separator/Separator"
import { Body, Heading } from "~/components/ui"
import { Pills } from "~/components/ui/Pills/Pills"

import css from "./CategorySection.module.scss"

interface PropTypes {
  home?: boolean
  Category: {
    parentSlug: string
    description: string
    slug: string
    name: string
    coverImage: string
  }
  part1: {
    parentSlug: string
    slug: string
    name: string
    coverImage: string
  }[]
  part2: {
    parentSlug: string
    slug: string
    name: string
    coverImage: string
  }[]
}
const CategorySection = ({
  Category,
  part1,
  part2,
  home,
  ...rest
}: PropTypes) => {
  return (
    <>
      {home ? (
        <Link to="h/categories" params={{ slug: Category.slug }}>
          <Heading variant={home ? "large" : "extraLarge"}>
            {Category.name}
          </Heading>
        </Link>
      ) : (
        <Heading variant={home ? "large" : "extraLarge"}>
          {Category.name}
        </Heading>
      )}
      {!home && (
        <>
          <Body>{Category.description}</Body>
          <Separator />
        </>
      )}
      <div className={css.listingImageCard}>
        {part1.map((child, index) => (
          <Link
            to="c/categories"
            params={{ category: child.slug }}
            className={css.imageContainer}
            key={index}
          >
            <Body
              className={css.title}
              variant="body1"
              bold
              uppercase
              color="white"
            >
              {child.name}
            </Body>
            <img
              className={css.imageCard}
              src={
                child.coverImage ??
                "https://cdn-staging.chefclub.tools/uploads/recipes/cover-thumbnail/db1ea234-519c-47f4-8822-7ac39c9558e3.jpg"
              }
            />
          </Link>
        ))}
      </div>
      <div className={cn(css.listingPills, { [css.home]: home })}>
        {part2.map((child, index) => (
          <Link
            to="c/categories"
            params={{ category: child.slug }}
            key={index}
            className={css.pills}
          >
            <Pills isRefined mainColor="var(--gray6-color)">
              <Body variant="body2" className={css.name} noMargin>
                {child.name}{" "}
              </Body>
            </Pills>
          </Link>
        ))}
      </div>
    </>
  )
}

export default CategorySection
