import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import RecipeCarousel from "../../Recipe/RecipeCarousel/RecipeCarousel"
import CategorySection from "../CategorySection/CategorySection"
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import { Container } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"
import { getCategoryLink } from "~/utils/link"
import { ChefclubLocale } from "~/utils/locales"

import css from "./Hub.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: HubPageQuery
  pageContext: TemplateDefaultPropTypes["pageContext"] & {
    lang: string
    slug: string
    categoryId: string
  }
}

const NUMBER_TILES = 6

function HubPage({ data, pageContext, ...props }: PropTypes) {
  const intl = useIntl()

  const longListing = data.ChildrensCategories.nodes.length > NUMBER_TILES
  const part1 = longListing
    ? data.ChildrensCategories.nodes.slice(0, NUMBER_TILES)
    : data.ChildrensCategories.nodes

  const part2 = longListing
    ? data.ChildrensCategories.nodes.slice(NUMBER_TILES)
    : []

  return (
    <div className={css.root}>
      <Breadcrumb template="Recipe" pageTitle={data.Category.name} />
      <Container>
        <section className={css.categoryContainer}>
          <CategorySection
            Category={data.Category}
            part1={part1}
            part2={part2}
          />
        </section>
      </Container>
      <section className={css.carouselContainer}>
        <RecipeCarousel
          title={intl.formatMessage(
            {
              id: "hub/text:last-recipes",
            },
            {
              category: data.Category.name.toLowerCase(),
            }
          )}
          recipes={data.sameCategoryCarousel.nodes}
          fullWidthClickable
          button={{
            label: intl.formatMessage({ id: "action:load-more" }),
            link: getCategoryLink(
              intl.locale as ChefclubLocale,
              pageContext.slug
            ),
          }}
        />
      </section>
    </div>
  )
}

interface HubPageQuery {
  Category: {
    parentSlug: string
    description: string
    slug: string
    name: string
    coverImage: string
  }
  ChildrensCategories: {
    nodes: {
      parentSlug: string
      slug: string
      name: string
      coverImage: string
    }[]
  }
  sameCategoryCarousel: { totalCount: number; nodes: PartialRecipe[] }
}

export const query = graphql`
  query HubPage($slug: String, $lang: String, $childrensCategories: [String]) {
    Category: category(slug: { eq: $slug }) {
      parentSlug
      description
      slug
      name
      coverImage
    }
    ChildrensCategories: allCategory(filter: { parentSlug: { eq: $slug } }) {
      nodes {
        parentSlug
        slug
        name
        coverImage
      }
    }
    sameCategoryCarousel: allRecipe(
      filter: {
        language: { eq: $lang }
        primary_category: { uuid: { in: $childrensCategories } }
      }
      limit: 5
    ) {
      totalCount
      nodes {
        ...PartialRecipe
      }
    }
  }
`

export default withTemplate(HubPage)
